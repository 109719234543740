import { Injectable } from '@angular/core';
import { IApiResponse, IResultResponse } from '../../core/models';
import { UtilsService } from '../utils/utils.service';
import { firstValueFrom, map, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { State } from '../../stores/reducers';
import { actionLoadMedia, actionSetupDirectoryMedia, IDirectoryTree } from '../../stores/actions';

export type TKeyRootDirectory = 'ALL' | 'ARTICLE' | 'GAMES' | 'MAIN' | 'PERSONA' | 'QUESTIONNAIRE' | 'VIDEO' | 'BUDGETPLANNER' | 'PRODUCT' | 'FINANCIAL';

export interface IMediaDirectoryItem {
  mdId: number;
  mdParentId: number;
  mdName: string;
  mdStatus: string;
  mediaObjectCount: number;
  fullPath: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private store: Store<State>,
  ) { }

  private refreshMediaDirectory = new Subject<void>();
  refreshMediaDirectory$ = this.refreshMediaDirectory.asObservable();

  triggerRefresh() {
    this.refreshMediaDirectory.next();
  }

  createMediaDirectory(body: any) {
    return this.http
      .post<any>('media-directory', body)
  }

  updateMediaDirectory(id: number, body: any) {
    return this.http
      .put<any>(`media-directory/${id}`, body)
  }

  deleteMediaDirectory(id: number) {
    return this.http
      .delete<any>(`media-directory/${id}`)
  }

  fetchMediaDirectory(page: number, limit: number = 10) {

    let url = 'media-directory';
    let queryParams: any = {
      limit: limit
    };

    if (page) {
      queryParams = {
        ...queryParams,
        page: page
      }
    }

    return this.http
      .get<IApiResponse<IResultResponse<any[]>>>(url, {
        params: queryParams
      })
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((data) => {
          if (data) {
            const result = data.result;
            const pagination = data.pagination;

            if (result) {
              return { result, pagination };
            }
          }
          return null;
        })
      );
  }

  createMediaObject(formData: FormData) {
    return this.http.post<any>('media-object', formData, {
      params: { isFormData: 'YES' }
    })
  }

  fetchMediaObject(directoryId: number, page: number) {
    let url = 'media-object';
    let queryParams = {};

    if (directoryId !== undefined && directoryId !== null) {
      queryParams = {
        ...queryParams,
        directoryId: directoryId
      }
    }

    if (page !== undefined && page !== null) {
      queryParams = {
        ...queryParams,
        page: page,
        limit: 18
      }
    }

    return this.http
      .get<IApiResponse<IResultResponse<any[]>>>(url, {
        params: queryParams
      })
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((data) => {
          if (data) {
            const result = data.result;
            const pagination = data.pagination;

            if (result) {
              return { result, pagination };
            }
          }
          return null;
        })
      );
  }

  deleteMediaObject(id: number) {
    return this.http
      .delete<any>(`media-object/${id}`)
  }

  fetchMediaAllDirectory(limit: number) {

    let url = 'media-directory';
    let queryParams = [];

    if (limit !== undefined && limit !== null) {
      queryParams.push(`limit=${limit}`);
    }

    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&');
    }

    return this.http
      .get<IApiResponse<IResultResponse<any[]>>>(url)
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((data) => {
          if (data) {
            const result = data.result;
            const pagination = data.pagination;

            if (result) {
              return { result, pagination };
            }
          }
          return null;
        })
      );
  }

  getMedia(): Observable<any> {
    let url = 'media-directory?limit=0';
    return this.http.get<IApiResponse<any>>(url).pipe(
      map(res => {
        const responseCodeAll = Number(res?.meta?.response_code || 0);
        if (responseCodeAll === 20000) {
          this.store.dispatch(actionLoadMedia(res));
        }

        return res?.data?.result as IMediaDirectoryItem[] ?? [];
      })
    );
  }

  fetchRootDirectory() {
    return this.http.get<IApiResponse<any>>(`config/directory`);
  }

  getRootId(rootDirectory: Exclude<TKeyRootDirectory, 'ALL'>) {
    return this.store.select('media').pipe(
      map(store => {
        return store?.root[rootDirectory] ?? null;
      })
    );
  }

  getDirectoryTree() {
    return this.store.select('media').pipe(
      map(store => (store?.data as IMediaDirectoryItem[]) ?? [])
    )
  }

  buildTreeSelectData(data: Array<IMediaDirectoryItem>) {
    const idMapping = data.reduce((acc: any, el: IMediaDirectoryItem) => {
      acc[el.mdId] = {
        title: el.mdName,
        isLeaf: el.mdParentId === 0 ? false : true,
        key: el.mdId.toString(),
        children: []
      };
      return acc;
    }, {});

    const treeSelectData: IDirectoryTree[] = [];
    data.forEach((el: IMediaDirectoryItem) => {
      if (el.mdParentId === 0) {
        treeSelectData.push(idMapping[el.mdId]);
      } else {
        if (idMapping[el.mdParentId]) {
          idMapping[el.mdParentId].children.push({
            ...idMapping[el.mdId],
            isLeaf: false
          });
        }
      }
    });

    return this.convertToTreeSelectFormat(treeSelectData);
  }

  convertToTreeSelectFormat(nodes: IDirectoryTree[]): IDirectoryTree[] {
    return nodes.map((node: IDirectoryTree) => ({
      ...node,
      isLeaf: node.children.length === 0,
      children: node.children.length > 0 ? this.convertToTreeSelectFormat(node.children) : [],
    }));
  }

  calculateExpandKeys(nodes: IDirectoryTree[]): string[] {
    return nodes.reduce((keys: string[], node: IDirectoryTree) => {
      if (node.children.length) {
        keys.push(`${node.key}`);
        keys.push(...this.calculateExpandKeys(node.children));
      }
      return keys;
    }, []);
  }

  getBreadcrumbDirectory(id: number, isHiddenPrefix: boolean = false): Observable<string> {
    return this.getDirectoryTree().pipe(
      map(dir => {
        const path = [];
        let currentId = id;

        for (; currentId;) {
          const parent = dir.find((item: any) => item.mdId == currentId);
          if (parent) {
            path.unshift(parent.mdName);
            currentId = parent.mdParentId;
          } else {
            break;
          }
        }
        return path.length > 0 ? `${isHiddenPrefix ? '' : 'ไฟล์ >'} ${path.join(' > ')}` : `${isHiddenPrefix ? '' : 'ไฟล์'}`;
      })
    )
  }

  filterData(nodes: any[], excludedKey: string): any[] {
    return nodes
      .filter(node => node.key !== excludedKey) 
      .map(node => {
        if (node.children) {
          node.children = this.filterData(node.children, excludedKey);
        }
        if (node.children && node.children.length === 0) {
          delete node.children; 
        }
        return node
      })
      .filter(node => node.children || node.key !== excludedKey);
  }

  addDashes(nodes: any[], depth: number = 0): any[] {
    return nodes.map(node => {
      node.title = '-'.repeat(depth) + ` ${node.title}`;

      if (node.children && node.children.length > 0) {
        node.children = this.addDashes(node.children, depth + 1);
      }

      return node;
    });
  }
  
  addDashesToFullPath(nodes: any[]): any[] {
    return nodes.map(node => {
      const depth = (node.fullPath.match(/>/g) || []).length;
      node.mdName = '-'.repeat(depth) + ' ' + node.mdName;

      if (node.children && node.children.length > 0) {
        node.children = this.addDashesToFullPath(node.children);
      }

      return node;
    });
  }

}
  

